.scrollToTop {
  align-items: center;
  background: color(secondary);
  border: 1px solid color(white);
  bottom: 5vw;
  cursor: pointer;
  color: color(white);
  display: none;
  height: 40px;
  justify-content: center;
  position: fixed;
  right: 5vw;
  width: 40px;
}
