.shopsubheader {
  color: color(lightest-gray);

  &--relations {
    background-color: color(lightest-gray);
    color: color(black);
  }

  &--title {
    color: color(darker-gray);
  }
}
