.labels {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 210mm;
}

.label-pos {
  flex: 0 0 70mm;
  height: 42.3mm;
  overflow: hidden;
  padding: 2mm 2mm;
  position: relative;
  white-space: nowrap;

  &__barcode,
  &__ean13,
  &__item_id,
  &__price,
  &__label,
  &__subtitle,
  &__image {
    font-size: 3.2mm;
    overflow: hidden;
    position: absolute;
  }

  &__barcode {
    display: flex;
    justify-content: center;
    left: 3mm;
    right: 3mm;
    top: 1mm;
    height: 9mm;

    > svg {
      // scale barcode to the full width
      transform: scale3d(1.4, 1, 1);
    }
  }

  &__ean13 {
    left: 3mm;
    right: 3mm;
    text-align: center;
    top: 10mm;
  }

  &__item_id {
    font-size: 12px;
    left: 5mm;
    top: 14mm;
  }

  &__label {
    left: 5mm;
    top: 19mm;
    max-width: 42mm;
    white-space: pre-wrap;
    font-size: 15px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 16mm;
    line-height: 4mm;
    -webkit-line-clamp: 3; /* Write the number of
                              lines you want to be
                              displayed */
    -webkit-box-orient: vertical;
  }

  &__subtitle {
    font-size: 14px;
    left: 5mm;
    top: 32mm;
    max-width: 40mm;
    line-height: 4mm;
    white-space: pre-wrap;
  }

  &__image {
    right: 3mm;
    bottom: 2mm;
    max-height: 20mm;
    max-width: 20mm;
    min-width: 20mm;
  }
}

.label-text {
  color: color(black);
  font-style: italic;
}
