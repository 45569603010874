@media print {
  .position-container__select__name {
    .button {
      display: none;
    }
  }

  // Einzelpreis
  .position-container {
    &__headline {
      > div:nth-child(2) {
        display: block;
        flex: 0 0 85px;
      }

      > div:nth-child(3) {
        display: block;
        flex: 0 0 210px;
      }

      > div:nth-child(4) {
        flex: 0 0 120px;
      }
    }
  }

  .container-item {
    &__options {
      display: none;
    }

    &__description {
      padding-right: 1rem;
    }

    &__prices {
      flex: 0 0 200px;
    }
  }
}
