@font-face {
  font-family: "FrutigerLT";
  src: url("../fonts/724833/a267d8d4-3e11-4eb4-a4a8-f109ddc63706.eot?#iefix");
  src: url("../fonts/724833/a267d8d4-3e11-4eb4-a4a8-f109ddc63706.eot?#iefix") format("eot"), url("../fonts/724833/ef9a4f38-e55d-40e8-aa22-b4510adec541.woff") format("woff"), url("../fonts/724833/dbe27c12-f5c5-449d-88b8-330313bf5865.ttf") format("truetype"), url("../fonts/724833/720956b5-5e1e-47f5-a115-34296dc4cbf1.svg#720956b5-5e1e-47f5-a115-34296dc4cbf1") format("svg");
}

@font-face {
  font-family: "FrutigerLT";
  src: url("../fonts/724845/70d83e26-5eda-46ec-95ba-643813861c3a.eot?#iefix");
  src: url("../fonts/724845/70d83e26-5eda-46ec-95ba-643813861c3a.eot?#iefix") format("eot"), url("../fonts/724845/2c61b2bd-b119-4f37-ba50-99d8159a29b7.woff") format("woff"), url("../fonts/724845/e48e72ec-22ed-4e7a-a891-62748411433f.ttf") format("truetype"), url("../fonts/724845/7dac69d8-812a-4c73-b79b-338e7813ed23.svg#7dac69d8-812a-4c73-b79b-338e7813ed23") format("svg");
  font-weight: bold;
}
