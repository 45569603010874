.shopfooter {
  &__contact-phone {
    list-style-type: none;
    margin-left: 2.5rem;
    text-align: left;
    white-space: nowrap;

    :nth-child(3) {
      padding-left: 0.5rem;
    }
  }
}
