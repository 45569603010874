.container-message {
  background-color: white;
  border: 2px solid #f9aa00;
  color: black;
  font-size: 16px;
  padding: 15px 15px 0 15px;

  strong {
    color: red;
  }
}

.position-container {
  &__headline {
    > div:nth-child(3) {
      flex: 0 0 275px;
      padding: 0;
      text-align: right;

      @include breakpoint(desktop) {
        flex: 0 0 325px;
      }
    }
  }

  &__select {
    &__value {
      flex: 0 1 auto;
      margin-right: 2rem;
    }
  }
}

.checkout__cart {
  .container-item {
    &__cart {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
}

.container-item {
  .item-package-unit {
    font-size: 13px;
    justify-content: flex-end;
  }

  &__cart {
    &--qty {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }

  &__qty {
    &--shoppinglist {
      .container-item__qty-button {
        top: -1px;
      }

      .input-group-field {
        max-width: 110px;
      }
    }
  }

  &__price-row {
    display: flex;
    justify-content: space-between;
  }

  &__price-unit {
    flex: 1 1 60%;
    font-size: 13px;
    line-height: 19px;
    text-align: left;
  }

  &__price {
    flex: 1 1 40%;
    padding-top: 0;
  }

  @include breakpoint(tablet) {
    &__flags {
      left: 0;
      right: auto;
    }

    &__price-row {
      padding-top: 5px;
      padding-left: 10px;

      &:first-child {
        padding-top: 10px;
      }
    }

    &__price-unit {
      text-align: right;
    }

    &__messages {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
    }
  }
}
