@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .shopheader__search {
    &-instant-results {
      width: 100% !important;
    }
  }

  .add-to-cart {
    &__extraarea {
      flex: 0 0 auto;
      width: auto;
    }
  }

  .cms-card__image {
    display: inline;
  }
}
