@media print {
  @page {
    size: 210mm 297mm;
    margin: 40mm 18mm 25mm 17mm;
  }

  .container-item {
    &__description {
      flex: 0 0 240px;
      width: 240px;
      padding-top: 2mm;
    }

    &__cart {
      flex: 1 0 280px;
      width: 280px;
      align-items: flex-start;
    }

    &__qty {
      input {
        height: auto;
        padding: 0;
      }

      &[data-unit]::after {
        top: -11px;
      }
    }

    &__prices.container-item__prices--unit {
      display: inherit;
    }
  }
}
