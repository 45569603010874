$shopheader-top-font-size: 0.8rem;

.shopheader {
  position: sticky;
  top: $shopheader-top-height * -1;

  &__language {
    padding-left: $global-padding;
    cursor: pointer;
  }

  &__middle {
    height: 90px;
  }

  &__logo {
    &-img {
      max-height: 80px;
    }
  }

  &__bottom {
    border-top: 1px solid color(lighter-gray);

    > .container {
      overflow: hidden;
    }

    &__cell {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      height: 62px;

      &--right {
        flex: 0 0 auto;
        justify-content: flex-end;
        min-width: 100px;
      }
    }
  }

  &__link {
    align-items: flex-start;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    left: -1 * $dropdown-padding;
    line-height: 20px;
    padding: 10px;
    text-align: center;
    text-transform: none;
    white-space: normal;

    &--6 {
      max-width: 160px;

      @include breakpoint(screen) {
        max-width: 220px;
      }

      @include breakpoint(wide) {
        max-width: 240px;
      }
    }

    &--7 {
      max-width: 160px;

      @include breakpoint(screen) {
        max-width: 180px;
      }

      @include breakpoint(wide) {
        max-width: 200px;
      }
    }

    &--8 {
      max-width: 160px;

      @include breakpoint(wide) {
        max-width: 180px;
      }
    }

    &--9 {
      max-width: 160px;
    }

    &--more {
      left: auto;
      right: -1 * $dropdown-padding;
      cursor: pointer;

      .icon {
        background-color: currentColor;
        border-radius: 2px;
        color: color(secondary);
        display: inline-block;
        height: 4px;
        margin-bottom: 4px;
        margin-left: 8px;
        position: relative;
        width: 4px;

        &::before,
        &::after {
          background-color: currentColor;
          border-radius: 2px;
          content: "";
          display: block;
          left: 0;
          height: 4px;
          top: -6px;
          position: absolute;
          width: 4px;
        }

        &::after {
          top: 6px;
        }
      }

      &:hover {
        background-color: color(primary);
        color: color(white);

        &::before {
          display: none;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }

  &__subnav {
    font-size: $shopheader-top-font-size;
    overflow-y: auto;
    max-height: calc(100vh - 240px);

    &__image {
      border-bottom: 1px solid color(lighter-gray);
      display: block;
      height: 112px;
      padding: 1rem 0;
      margin-bottom: 8px;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    &__cell {
      margin-bottom: 2rem;
    }

    &__link {
      margin-bottom: 2px;
    }

    &__title {
      color: color(black);
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: 1.2;
      margin-bottom: 4px;

      &.separator-left {
        &::after {
          margin: 8px 0;
          border-bottom: 2px solid color(primary);
        }
      }
    }

    &--discover {
      border-top: 0;
      padding: 0;
      z-index: 25;
      max-height: calc(100vh - 38px);

      > header {
        background-color: color(secondary);
        font-size: $shopheader-top-font-size;

        .container {
          display: flex;
          justify-content: space-between;
        }

        .shopheader-discover {
          &::before,
          &::after {
            display: none;
          }
        }

        button {
          outline: none;

          * {
            color: color(white);
            display: inline-block;
            margin: 0;
          }
        }
      }

      > .container {
        > .row {
          border-top: 1px solid color(lighter-gray);
          padding-bottom: 1rem;
          padding-top: 1rem;

          &:first-child {
            border-top-width: 0;
          }

          .cell {
            border-right: 1px solid color(lighter-gray);
            font-size: 14px;

            &:nth-child(2n) {
              border-right-width: 0;
            }

            @include breakpoint(tablet) {
              &:nth-child(2n) {
                border-right-width: 1px;
              }

              &:nth-child(3n) {
                border-right-width: 0;
              }
            }

            @include breakpoint(laptop) {
              &:nth-child(3n) {
                border-right-width: 1px;
              }

              &:nth-child(4n) {
                border-right-width: 0;
              }
            }

            &:last-child {
              border-right-width: 0;
            }
          }

          .discover-logo {
            text-align: right;
            margin-top: 0;

            img {
              height: 110px;
            }
          }
        }
      }
    }
  }
}

.shopheader-menu {
  &--languages {
    &::before {
      right: 10px;
      left: initial;
    }
  }

  &--reseller::before {
    right: 21px;
  }
}

.shopheader-discover {
  align-items: center;
  background-color: color(secondary);
  display: flex;
  font-size: $shopheader-top-font-size;
  height: $shopheader-top-height;
  outline: 0;
  position: relative;

  &::before,
  &::after {
    background-color: color(secondary);
    bottom: 0;
    content: "";
    display: block;
    top: 0;
    position: absolute;
    z-index: 1;
  }

  &::before {
    left: -2000px;
    right: 0;
  }

  &::after {
    transform-origin: bottom left;
    transform: skewX(-12deg);
    right: 0;
    width: 15px;
  }

  > i {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 16px;
    z-index: 2;

    > span {
      background-color: color(white);
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 1px);
      transition: background-color 0.2s linear;
    }

    &::before,
    &::after {
      background-color: color(white);
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 5px);
      transition: all 0.2s linear;
    }

    &::after {
      top: calc(50% + 3px);
    }
  }

  > span {
    color: color(white);
    font-weight: 700;
    margin: 0;
    padding: 0 $global-padding;
    z-index: 2;
  }
}

.hamburger-item {
  &--catalog {
    background-color: color(primary);
  }
}
