@import "poly-eshop4/eshop/ui/scss/settings";

$body-font-family: "FrutigerLT", "OpenSans", "Helvetica", "Arial", sans-serif;
$global-font-size: 110%;

$foundation-palette: map-merge(
  $foundation-palette,
  (
    primary: #007ec6,
    secondary: #007ec6,
  )
);

$shopheader-main-navigation-color: color(primary);
$subheader-background-color: color(primary);
$subheader-font-color: color(secondary);

$discontinued-color: #fff;
$discontinued-bg-color: #007ec6;
